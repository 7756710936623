import React from "react";
import Resume from "./components/Resume/resume";
import PrintButton from "./components/PrintButton/PrintButton";

function App() {
  return (
    <div>
      <Resume />
      <div
        style={{
          padding: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PrintButton />
      </div>
    </div>
  );
}

export default App;
