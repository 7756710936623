// src/Resume.js
import React from "react";
import "./Resume.css"; // Optional for styling
import logo from "../../static/images/ram2.png";
import { FaMobileAlt } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { FaLinkedinIn } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { FaUserGraduate } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import { GiAchievement } from "react-icons/gi";
import { FaGithubSquare } from "react-icons/fa";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

const Resume = () => {
  return (
    <div className="resume">
      <div className="header">
        <div className="blankDiv"></div>
        <div className="contentCover">
          <div className="headerContent">
            <div className="imageDiv">
              <img src={logo} alt="profilePic" id="profilePic" />
            </div>
            <div>
              <h1>Ramsewak</h1>
              <h2>Full Stack Web Developer</h2>
              <p>
                Self-motivated result-driven full-stack web developer with a
                strong background in designing, coding, and maintaining
                responsive websites and applications.
              </p>

              <p>
                Proficient in front-end technologies such as{" "}
                <strong>HTML, CSS, Javascript and React Js</strong>, as well as
                back-end framework like <strong>Express Js</strong> with{" "}
                <strong>MongoDB</strong> as a database.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mainBody">
        <div className="sideSection">
          <div className="contactDiv">
            <h1>Contact</h1>
            <hr />
            <div className="contactElements">
              <FaMobileAlt />
              <span>+91 7017799062</span>
            </div>
            <hr />
            <div className="contactElements">
              <BiLogoGmail />
              <span
                onClick={() =>
                  window.open(
                    `https://mail.google.com/mail/?view=cm&fs=1&to=ram.sewak0015@gmail.com`,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                Ram.sewak0015@gmail.com
              </span>
            </div>
            <hr />
            <div className="contactElements">
              <FaLinkedinIn />
              <span
                onClick={() =>
                  window.open(
                    `https://linkedin.com/in/ram-sewak-6179881b4/`,
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                www.linkedin.com/in/ram-sewak-6179881b4
              </span>
            </div>
            <hr />
            <div className="contactElements">
              <TbWorld />
              <span
                onClick={() =>
                  window.open(` https://ramsewak.rvsm.co.in`, "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                https://ramsewak.rvsm.co.in
              </span>
            </div>
            <hr />
            <div className="contactElements">
              <FaGithubSquare />
              <span
                onClick={() =>
                  window.open(`https://github.com/ram0501`, "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                https://github.com/ram0501
              </span>
            </div>
            <hr />
          </div>
          <div className="educationDiv">
            <h1>Education</h1>
            <hr />
            <div className="educationElements">
              <FaUserGraduate />
              <h3>Bachelor Of Technology</h3>
              <p>
                DR. A.P.J. Abdul Kalam Technical University , Lucknow,&nbsp;
                <strong>73%</strong>
              </p>
              <p>2015 - 2019</p>
            </div>
            <hr />
            <div className="educationElements">
              <FaGraduationCap />
              <h3>12th</h3>
              <p>
                Kendriya Vidyalaya Baad No.3 Mathura,&nbsp;
                <strong>87%</strong>
              </p>
              <p>2012 - 2013</p>
            </div>
            <hr />
            <div className="educationElements">
              <FaGraduationCap />
              <h3>10th</h3>
              <p>
                Kendriya Vidyalaya Baad No.3 Mathura,&nbsp;
                <strong>9.6 CGPA</strong>
              </p>
              <p>2010 - 2011</p>
            </div>
            <hr />
          </div>
          <div className="softSkills">
            <h1>Soft Skills</h1>
            <p>Time Management</p>
            <p>Problem-Solving</p>
            <p>Communication</p>
            <p>Adaptability</p>
            {/* <p>Teamwork</p> */}
          </div>
        </div>
        <div className="mainSection">
          <div className="experienceDiv">
            <h1>Work Experience</h1>
            <p>Full Stack Web Developer</p>
            <p id="companyName">RVSM Solutions Private Limited</p>
            <p id="workingYears">
              <span>Jan 2022 - Present</span>
              <span>Hybrid</span>
            </p>
            <p>
              The company is newly founded in 2019 an aged start-up in the
              telecommunication business. The company has grown significantly
              during and post-Covid. I got the opportunity to work in the
              company on a website development project as a freelancing
              contract. Eventually, the web application has played a vital role
              in growing business in multiple folds. The area of service has
              grown significantly, looking at the expansion of the business the
              director decided to hire me as a software developer in the
              company. To date, the company has hired 10+ software engineers
              including designers.
            </p>
            <p>Below are the synapsis of the work details :-</p>
            <ul>
              <li>
                Developed official web application that expanded service area
                and significantly increased customer base for the company.
              </li>
              <li>
                Developed RVSM admin control web application with the
                below-mentioned features :-
                <ul>
                  <li>
                    Admin can give access to promoter/director to sign up new
                    users.
                  </li>
                  <li>
                    Admin can download real-time data of customers in Excel who
                    have enquired through the platform.
                  </li>
                  <li>
                    Empower admins with the ability to seamlessly add, update,
                    or delete individual broadband plans.
                  </li>
                  <li>
                    Functionality enables dynamic changes to broadband plans,
                    including price revisions and plan additions.
                  </li>
                </ul>
              </li>
              <li>
                {" "}
                Developed web application for Payroll management for the company
                .
              </li>
              <li>
                Utilized version control systems like <strong>Git</strong> to
                manage code changes and facilitate collaborative development.
              </li>
              <li>
                Implemented <strong>responsive design</strong> principles to
                ensure compatibility across various devices and browsers.
              </li>
              <li>
                Hosted web applications using <strong>Nginx</strong> a web
                hosting server.
              </li>
              <li>
                Trained new team members about the work thoroughly and assigned
                new roles after discussing with directors
              </li>
            </ul>
            <h1>Other Experience</h1>
            <p>Developed 15+ freelancing websites (2019-2022)</p>
            <ul>
              <li>Job Portal web application</li>
              <li>e-commmerce web application</li>
              <li>Photography web application</li>
              <li>Quiz application etc.</li>
            </ul>
          </div>
          <div className="certifications">
            <h1>
              <GiAchievement />
              &nbsp;Courses & Certifications
            </h1>
            <p
              className="urlClass_para"
              onClick={() =>
                window.open(
                  `https://ramsewaksresume.rvsm.co.in/ramsewaks_rvsm.pdf`,
                  "_blank"
                )
              }
            >
              Certificate Of Appreciation | RVSM Solutions Private Limited
            </p>
            <p
              className="urlClass"
              onClick={() =>
                window.open(
                  `https://ramsewaksresume.rvsm.co.in/ramsewaks_rvsm.pdf`,
                  "_blank"
                )
              }
            >
              Url : - https://ramsewaksresume.rvsm.co.in/ramsewaks_rvsm.pdf
            </p>
            <hr />
            <p
              className="urlClass_para"
              onClick={() =>
                window.open(
                  `https://certificate.codingninjas.com/verify/8a45686eb63223fd`,
                  "_blank"
                )
              }
            >
              Front-End | Full Stack Web Development
            </p>
            <p
              className="urlClass"
              onClick={() =>
                window.open(
                  `https://certificate.codingninjas.com/verify/8a45686eb63223fd`,
                  "_blank"
                )
              }
            >
              Url : -
              https://certificate.codingninjas.com/verify/8a45686eb63223fd
            </p>
            <hr />
            <p
              className="urlClass_para"
              onClick={() =>
                window.open(
                  `https://certificate.codingninjas.com/view/894e8914f9121c94`,
                  "_blank"
                )
              }
            >
              Advance Front-End Web Development with React - Full Stack
            </p>
            <p
              className="urlClass"
              onClick={() =>
                window.open(
                  `https://certificate.codingninjas.com/view/894e8914f9121c94`,
                  "_blank"
                )
              }
            >
              Url : - https://certificate.codingninjas.com/view/894e8914f9121c94
            </p>
            <hr />
            <p
              className="urlClass_para"
              onClick={() =>
                window.open(
                  `https://certificate.codingninjas.com/view/56b32dbb6151768b`,
                  "_blank"
                )
              }
            >
              Back-End | Full Stack Web Development In Node.js
            </p>
            <p
              className="urlClass"
              onClick={() =>
                window.open(
                  `https://certificate.codingninjas.com/view/56b32dbb6151768b`,
                  "_blank"
                )
              }
            >
              Url : - https://certificate.codingninjas.com/view/56b32dbb6151768b
            </p>
            <hr />
          </div>

          <div className="skills">
            <h1>Skills</h1>
            <div className="skills-grid">
              <div className="skills-grid-element1">
                HTML
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                </span>
              </div>
              <div className="skills-grid-element2">
                CSS
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                </span>
              </div>
              <div className="skills-grid-element3">
                Javascript
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                </span>
              </div>
              <div className="skills-grid-element1">
                React Js
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                </span>
              </div>
              <div className="skills-grid-element2">
                Node Js
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                  <FaRegStar />
                </span>
              </div>
              <div className="skills-grid-element3">
                Express Js
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                </span>
              </div>
              <div className="skills-grid-element1">
                MongoDB
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                  <FaRegStar />
                </span>
              </div>
              <div className="skills-grid-element2">
                Mongoose
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                  <FaRegStar />
                </span>
              </div>
              <div className="skills-grid-element3">
                Git
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                </span>
              </div>
              <div className="skills-grid-element1">
                Nginx
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                  <FaRegStar />
                </span>
              </div>
            </div>
          </div>
          <div className="languages">
            <h1>Languages</h1>
            <div className="language_para">
              <p className="language_para1">
                English{" "}
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStarHalfAlt />
                </span>
              </p>
              <p className="language_para2">
                Hindi{" "}
                <span>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
