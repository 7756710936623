// src/PrintButton.js
import React from "react";
import ReactToPrint from "react-to-print";

const PrintButton = () => {
  return (
    <ReactToPrint
      trigger={() => <button>Print Resume</button>}
      content={() => document.querySelector(".resume")}
    />
  );
};

export default PrintButton;
